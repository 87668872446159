import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "mb-5 mb-xl-8 card" }
const _hoisted_2 = { class: "card-header border-0 pt-5" }
const _hoisted_3 = { class: "card-title align-items-start flex-column" }
const _hoisted_4 = { class: "card-label fw-bolder fs-3 mb-1" }
const _hoisted_5 = { class: "text-muted mt-1 fw-bold fs-7" }
const _hoisted_6 = { class: "align-items-end flex-column" }
const _hoisted_7 = {
  href: "#",
  class: "btn btn-primary btn-light",
  "data-bs-toggle": "modal",
  "data-bs-target": "#kt_modal_1"
}
const _hoisted_8 = { class: "card-body py-3" }
const _hoisted_9 = { class: "table-responsive" }
const _hoisted_10 = { class: "table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3" }
const _hoisted_11 = { class: "fw-bolder text-muted" }
const _hoisted_12 = { class: "min-w-150px" }
const _hoisted_13 = { class: "min-w-100px text-end" }
const _hoisted_14 = {
  href: "#",
  class: "text-dark fw-bolder text-hover-primary fs-6"
}
const _hoisted_15 = { class: "text-end" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = {
  class: "modal fade",
  tabindex: "-1",
  id: "kt_modal_1"
}
const _hoisted_18 = { class: "modal-dialog" }
const _hoisted_19 = { class: "modal-content" }
const _hoisted_20 = { class: "modal-header" }
const _hoisted_21 = { class: "modal-title" }
const _hoisted_22 = { class: "modal-body" }
const _hoisted_23 = { class: "row mb-6" }
const _hoisted_24 = { class: "col-lg-12 col-form-label required fw-bold fs-6" }
const _hoisted_25 = { class: "col-lg-12" }
const _hoisted_26 = { class: "row" }
const _hoisted_27 = { class: "col-lg-12 fv-row" }
const _hoisted_28 = { class: "modal-footer" }
const _hoisted_29 = {
  type: "button",
  class: "btn btn-light",
  "data-bs-dismiss": "modal"
}
const _hoisted_30 = { class: "indicator-label" }
const _hoisted_31 = { class: "indicator-progress" }
const _hoisted_32 = { class: "indicator-validate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h3", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("pages.roadmap.title")), 1),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("pages.roadmap.themeManagement")), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("a", _hoisted_7, _toDisplayString(_ctx.$t("pages.roadmap.addTheme")), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("table", _hoisted_10, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", _hoisted_11, [
                _createElementVNode("th", _hoisted_12, _toDisplayString(_ctx.$t("pages.roadmap.themeName")), 1),
                _createElementVNode("th", _hoisted_13, _toDisplayString(_ctx.$t("pages.usersList.actions")), 1)
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: category.pk
                }, [
                  _createElementVNode("td", null, [
                    _createElementVNode("a", _hoisted_14, _toDisplayString(category.label), 1)
                  ]),
                  _createElementVNode("td", _hoisted_15, [
                    _createElementVNode("a", {
                      onClick: ($event: any) => (this.initEdit(category)),
                      "data-bs-toggle": "modal",
                      "data-bs-target": "#kt_modal_1",
                      class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                    }, _cache[2] || (_cache[2] = [
                      _createElementVNode("span", { class: "svg-icon svg-icon-3" }, [
                        _createElementVNode("svg", {
                          fill: "none",
                          viewBox: "0 0 24 24",
                          height: "24",
                          width: "24",
                          xmlns: "http://www.w3.org/2000/svg"
                        }, [
                          _createElementVNode("path", {
                            xmlns: "http://www.w3.org/2000/svg",
                            opacity: "0.3",
                            d: "M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z",
                            fill: "black"
                          }),
                          _createElementVNode("path", {
                            xmlns: "http://www.w3.org/2000/svg",
                            d: "M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z",
                            fill: "black"
                          })
                        ])
                      ], -1)
                    ]), 8, _hoisted_16)
                  ])
                ]))
              }), 128))
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("h5", _hoisted_21, _toDisplayString(_ctx.$t("pages.roadmap.addEditTheme")), 1),
            _cache[3] || (_cache[3] = _createElementVNode("div", {
              class: "btn btn-icon btn-sm btn-active-light-primary ms-2",
              "data-bs-dismiss": "modal",
              "aria-label": "Close"
            }, [
              _createElementVNode("span", { class: "svg-icon svg-icon-2x" })
            ], -1))
          ]),
          _createElementVNode("div", _hoisted_22, [
            _createVNode(_component_Form, {
              id: "kt_add_action_form",
              class: "form",
              novalidate: "novalidate"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("label", _hoisted_24, _toDisplayString(_ctx.$t("pages.roadmap.themeName")), 1),
                  _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("div", _hoisted_26, [
                      _createElementVNode("div", _hoisted_27, [
                        _createVNode(_component_Field, {
                          type: "text",
                          class: "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                          placeholder: _ctx.$t('pages.roadmap.themeName'),
                          name: "categoryLabel",
                          modelValue: this.category.label,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.category.label) = $event))
                        }, null, 8, ["placeholder", "modelValue"])
                      ])
                    ])
                  ])
                ])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_28, [
            _createElementVNode("button", _hoisted_29, _toDisplayString(_ctx.$t("general.close")), 1),
            _createElementVNode("button", {
              type: "button",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.addCategory && _ctx.addCategory(...args))),
              id: "kt_account_profile_details_submit",
              ref: "submitButton",
              class: "btn btn-primary"
            }, [
              _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.$t("general.add")), 1),
              _createElementVNode("span", _hoisted_31, [
                _createTextVNode(_toDisplayString(_ctx.$t("general.pleaseWait")) + " ", 1),
                _cache[4] || (_cache[4] = _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" }, null, -1))
              ]),
              _createElementVNode("span", _hoisted_32, [
                _createTextVNode(_toDisplayString(_ctx.$t("general.done")) + " ", 1),
                _cache[5] || (_cache[5] = _createElementVNode("span", { class: "fas fa-check f-5 ms-3" }, null, -1))
              ])
            ], 512)
          ])
        ])
      ])
    ])
  ], 64))
}